import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Button, Checkbox, Label, Table } from 'flowbite-react';
import { Helmet } from 'react-helmet';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { type Analytic } from 'types/analytic';
import { type ChannelGroup } from 'types/channel_group';
import { type PageScreen } from 'types/page_screen';
import {
  type AccessDataGraphType,
  getAccessData,
  graphLabelObject,
  type GraphMetrics,
  type ProgressData,
} from 'domains/api/getAccessData';
import {
  type ChannelGroupsResponse,
  getChannelGroups,
} from 'domains/api/getChannelGroups';
import { getDownloads } from 'domains/api/getDownloads';
import {
  getPageScreens,
  type PageScreensResponse,
} from 'domains/api/getPageScreens';
import { getRegisteredKeyEvents } from 'domains/api/getRegisteredKeyEvents';
import { usePageTracking } from 'utils/ga';
import { StringUtil } from 'utils/string-util';
import KeyIndicatorWithProgressBar from 'components/molecules/Analytics/KeyIndicatorWithProgressBar';
import { getDownloadsSearchConsole } from '../../../domains/api/getDownloadsSearchConsole.ts';

const AnalyticPage: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測
  const analytic = useLoaderData() as Analytic;
  const [searchParams, setSearchParams] = useSearchParams();
  const [graphData, setGraphData] = useState<AccessDataGraphType[] | undefined>(
    undefined,
  );
  const [downloadingAnalyticReport, setDownloadingAnalyticReport] =
    useState<boolean>(false);
  const [downloadingSearchConsoleReport, setDownloadingSearchConsoleReport] =
    useState<boolean>(false);
  const getInitialDate = (): string => {
    const today = new Date();
    today.setDate(today.getDate() - 1);

    return today.toISOString().split('T')[0];
  };
  const [criteriaDate, setCriteriaDate] = useState<Date>(
    new Date(searchParams.get('criteria_date') ?? getInitialDate()),
  );
  const [metrics, setMetrics] = useState<GraphMetrics>('pageViews');
  const [cumulative, setCumulative] = useState<boolean>(true);
  const [progressData, setProgressData] = useState<ProgressData | undefined>(
    undefined,
  );
  const [channelGroups, setChannelGroups] = useState<
    ChannelGroupsResponse | undefined
  >(undefined);
  const [pageScreens, setPageScreens] = useState<
    PageScreensResponse | undefined
  >(undefined);
  const [graphLabelObjectWithKeyEvents, setGraphLabelObjectWithKeyEvents] =
    useState<Record<string, string> | undefined>(graphLabelObject);
  useEffect(() => {
    void (async () => {
      const response = await getAccessData(analytic.id, searchParams);
      const channelGroups = await getChannelGroups(analytic.id, searchParams);
      const pageScreens = await getPageScreens(analytic.id, searchParams);
      const registeredKeyEventResponse = await getRegisteredKeyEvents(
        analytic.id,
      );
      const sumData = response.slice(-1)[0];
      setProgressData({
        pageViews: {
          currentMonth: sumData.cumulativePageViews,
          prevMonth: sumData.cumulativePrevMonthPageViews,
          prevYearMonth: sumData.cumulativePrevYearMonthPageViews,
          monthOnMonth:
            Math.round(
              (sumData.cumulativePageViews /
                sumData.cumulativePrevMonthPageViews) *
                100 *
                10,
            ) / 10,
          yearOnYear:
            Math.round(
              (sumData.cumulativePageViews /
                sumData.cumulativePrevYearMonthPageViews) *
                100 *
                10,
            ) / 10,
        },
        sessions: {
          currentMonth: sumData.cumulativeSessions,
          prevMonth: sumData.cumulativePrevMonthSessions,
          prevYearMonth: sumData.cumulativePrevYearMonthSessions,
          monthOnMonth:
            Math.round(
              (sumData.cumulativeSessions /
                sumData.cumulativePrevMonthSessions) *
                100 *
                10,
            ) / 10,
          yearOnYear:
            Math.round(
              (sumData.cumulativeSessions /
                sumData.cumulativePrevYearMonthSessions) *
                100 *
                10,
            ) / 10,
        },
        users: {
          currentMonth: sumData.cumulativeUsers,
          prevMonth: sumData.cumulativePrevMonthUsers,
          prevYearMonth: sumData.cumulativePrevYearMonthUsers,
          monthOnMonth:
            Math.round(
              (sumData.cumulativeUsers / sumData.cumulativePrevMonthUsers) *
                100 *
                10,
            ) / 10,
          yearOnYear:
            Math.round(
              (sumData.cumulativeUsers / sumData.cumulativePrevYearMonthUsers) *
                100 *
                10,
            ) / 10,
        },
      });
      setGraphData(response);
      setChannelGroups(channelGroups);
      setPageScreens(pageScreens);
      const keyEvents: Record<string, string> = {};
      registeredKeyEventResponse.keyEvents.forEach((keyEvent) => {
        keyEvents[keyEvent.apiName] = keyEvent.name;
      });
      setGraphLabelObjectWithKeyEvents({ ...graphLabelObject, ...keyEvents });
    })();
  }, [searchParams]);
  const handleCriteriaDate = (date: Date) => {
    setCriteriaDate(date);
    setSearchParams({
      criteria_date: `${date.getFullYear()}/${date.getMonth() + 1}`,
    });
  };

  const selectMetrics = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMetrics(e.target.value);
  };

  const clickCumulative = () => {
    setCumulative(!cumulative);
  };

  const downloadAnalyticReport = async () => {
    setDownloadingAnalyticReport(true);
    const response = await getDownloads(analytic.id, searchParams);
    if (response.ok) {
      const blob = await response.blob();
      saveAs(blob, 'analytics_monthly_report.xlsx');
      setDownloadingAnalyticReport(false);
    } else {
      setDownloadingAnalyticReport(false);
      throw new Error('Network response was not ok.');
    }
  };

  const downloadSearchConsoleReport = async () => {
    setDownloadingSearchConsoleReport(true);
    const response = await getDownloadsSearchConsole(analytic.id, searchParams);
    if (response.ok) {
      const blob = await response.blob();
      saveAs(blob, 'search_console_monthly_report.xlsx');
      setDownloadingSearchConsoleReport(false);
    } else {
      setDownloadingSearchConsoleReport(false);
      throw new Error('Network response was not ok.');
    }
  };

  return (
    <>
      <Helmet>
        <title>{analytic.name}: サイト分析、レポートはGrowth Support</title>
      </Helmet>
      <div className="border-b py-3 pl-2 flex justify-between items-center">
        <h1 className="text-xl leading-none tracking-tight text-gray-900 md:text-xl dark:text-white">
          {analytic.name}
        </h1>
      </div>
      <div className="overflow-x-auto py-5 content-area">
        <h2 className="text-lg flex justify-between mb-2">
          <span className="flex items-center gap-6 text-md">
            {criteriaDate.getMonth() + 1}月:
            <select
              id="metrics"
              className="border border-gray-200 text-gray-900 text-md leading-5 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              onChange={selectMetrics}
            >
              {Object.keys(graphLabelObjectWithKeyEvents).map((key) => (
                <option key={key} value={key}>
                  {graphLabelObjectWithKeyEvents[key]}
                </option>
              ))}
            </select>
            <div className="flex items-center gap-2 text-md">
              <Checkbox
                id="cumulative"
                checked={cumulative}
                onChange={clickCumulative}
              />
              <Label htmlFor="cumulative" className="text-md">
                累積
              </Label>
            </div>
            <Button
              color="gray"
              onClick={downloadAnalyticReport}
              isProcessing={downloadingAnalyticReport}
            >
              <span className={'text-md'}>レポート DL</span>
            </Button>
            {analytic.searchConsole && (
              <Button
                color="gray"
                onClick={downloadSearchConsoleReport}
                isProcessing={downloadingSearchConsoleReport}
              >
                <span className={'text-md'}>Search Console レポート DL</span>
              </Button>
            )}
          </span>
          <div className="inline-flex mt-2 xs:mt-0">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <div
                  role="button"
                  tabIndex={0}
                  className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                  onClick={() => {
                    handleCriteriaDate(
                      new Date(
                        criteriaDate.getFullYear(),
                        criteriaDate.getMonth() - 1,
                        criteriaDate.getDate(),
                      ),
                    );
                  }}
                  onKeyDown={() => {
                    handleCriteriaDate(
                      new Date(
                        criteriaDate.getFullYear(),
                        criteriaDate.getMonth() - 1,
                        criteriaDate.getDate(),
                      ),
                    );
                  }}
                >
                  Previous
                </div>
              </li>
              <li>
                <div
                  aria-current="page"
                  className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white cursor-default"
                >
                  {criteriaDate.getFullYear()}/{criteriaDate.getMonth() + 1}
                </div>
              </li>
              <li>
                <div
                  role="button"
                  tabIndex={0}
                  className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                  onClick={() => {
                    handleCriteriaDate(
                      new Date(
                        criteriaDate.getFullYear(),
                        criteriaDate.getMonth() + 1,
                        criteriaDate.getDate(),
                      ),
                    );
                  }}
                  onKeyDown={() => {
                    handleCriteriaDate(
                      new Date(
                        criteriaDate.getFullYear(),
                        criteriaDate.getMonth() + 1,
                        criteriaDate.getDate(),
                      ),
                    );
                  }}
                >
                  Next
                </div>
              </li>
            </ul>
          </div>
        </h2>
        <ResponsiveContainer width="100%" height="100%">
          <>
            <div className="flex">
              <LineChart
                width={1000}
                height={400}
                data={graphData ?? undefined}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" angle={45} height={32} />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={30} />
                <Line
                  type="monotone"
                  dataKey={StringUtil.snakeToCamel(
                    `${cumulative ? 'cumulative_' : ''}${metrics}`,
                  )}
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                  name="今月"
                />
                <Line
                  type="monotone"
                  dataKey={StringUtil.snakeToCamel(
                    `${cumulative ? 'cumulative_' : ''}prevMonth_${metrics}`,
                  )}
                  stroke="#82ca9d"
                  name="前月"
                />
                <Line
                  type="monotone"
                  dataKey={StringUtil.snakeToCamel(
                    `${
                      cumulative ? 'cumulative_' : ''
                    }prevYearMonth_${metrics}`,
                  )}
                  stroke="#ffc658"
                  name="前年同月"
                />
              </LineChart>
              <div className="w-80 m-4">
                {progressData && (
                  <>
                    <KeyIndicatorWithProgressBar
                      title="ページビュー数"
                      keyIndicator={progressData.pageViews}
                    />
                    <KeyIndicatorWithProgressBar
                      title="セッション数"
                      keyIndicator={progressData.sessions}
                    />
                    <KeyIndicatorWithProgressBar
                      title="ユーザー数"
                      keyIndicator={progressData.users}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="overflow-x-auto mt-14">
              <h3 className="text-lg m-5 font-bold">
                流入経路(セッションのメインのチャネル グループ)
              </h3>
              <Table>
                <Table.Head>
                  <Table.HeadCell>チャネルグループ</Table.HeadCell>
                  <Table.HeadCell className="text-right">
                    ユーザー数
                  </Table.HeadCell>
                  <Table.HeadCell className="text-right">
                    新規ユーザー数
                  </Table.HeadCell>
                  <Table.HeadCell className="text-right">
                    セッション数
                  </Table.HeadCell>
                  <Table.HeadCell className="text-right">
                    エンゲージメント率
                  </Table.HeadCell>
                  <Table.HeadCell className="text-right">
                    エンゲージのあったセッション数(1ユーザーあたり)
                  </Table.HeadCell>
                  <Table.HeadCell className="text-right">
                    平均エンゲージメント時間
                  </Table.HeadCell>
                  <Table.HeadCell className="text-right">
                    イベント数
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {channelGroups?.currentMonthChannelGroups.map(
                    (channelGroup: ChannelGroup) => (
                      <Table.Row
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        key={channelGroup.id}
                      >
                        <Table.Cell>{channelGroup.channelGroup}</Table.Cell>
                        <Table.Cell className="text-right">
                          {channelGroup.users}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {channelGroup.newUsers}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {channelGroup.sessions}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {Math.round(channelGroup.engagementRate * 10000) /
                            100}
                          %
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {channelGroup.users === 0
                            ? 0
                            : Math.round(
                                (channelGroup.engagedSessions /
                                  channelGroup.users) *
                                  100,
                              ) / 100}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {channelGroup.users === 0
                            ? 0
                            : Math.round(
                                channelGroup.userEngagementDuration /
                                  channelGroup.users,
                              )}
                          秒
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {channelGroup.eventCount}
                        </Table.Cell>
                      </Table.Row>
                    ),
                  )}
                </Table.Body>
              </Table>
            </div>
            <div className="overflow-x-auto mt-14">
              <h3 className="text-lg m-5 font-bold">
                人気ページ(ページパスとスクリーン クラス)
              </h3>
              {pageScreens && (
                <Table>
                  <Table.Head>
                    <Table.HeadCell>
                      ページパスとスクリーン クラス
                    </Table.HeadCell>
                    <Table.HeadCell className="text-right">
                      表示回数
                    </Table.HeadCell>
                    <Table.HeadCell className="text-right">
                      ユーザー数
                    </Table.HeadCell>
                    <Table.HeadCell className="text-right">
                      ユーザーあたりのビュー
                    </Table.HeadCell>
                    <Table.HeadCell className="text-right">
                      平均エンゲージメント時間
                    </Table.HeadCell>
                    <Table.HeadCell className="text-right">
                      イベント数
                    </Table.HeadCell>
                    <Table.HeadCell className="text-right">
                      コンバージョン数
                    </Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {pageScreens.pageScreens.map((pageScreen: PageScreen) => (
                      <Table.Row key={pageScreen.id}>
                        <Table.Cell>{pageScreen.unifiedScreenClass}</Table.Cell>
                        <Table.Cell className="text-right">
                          {pageScreen.screenPageViews}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {pageScreen.users}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {pageScreen.users === 0
                            ? 0
                            : Math.round(
                                (pageScreen.screenPageViews /
                                  pageScreen.users) *
                                  100,
                              ) / 100}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {pageScreen.users === 0
                            ? 0
                            : Math.round(
                                pageScreen.userEngagementDuration /
                                  pageScreen.users,
                              )}
                          秒
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {pageScreen.eventCount}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          {pageScreen.conversions}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </div>
          </>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default AnalyticPage;
